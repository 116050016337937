import React, { FunctionComponent } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import { Layout } from '../components/LayoutLanding'
import { HomeSoFastVideoSection } from '../components/HomeSoFastVideoSection'
import { SEO } from '../components/SEO'

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Vanna | Infraestrutura de split para Pix"
        description={
          'Use nossa infraestrutura de split para receber Pix já aplicando regras onde os valores caem nas contas Vanna de seus dependentes'
        }
        tags={[
          'Infraestrutura',
          'Split',
          'Pagamentos instanâneos',
          'Pix',
          'Receba em segundos',
        ]}
        canonical="https://vanna.app/"
      />
      <section className="relative overflow-hidden">
        <div className="pt-6 pb-12 sm:pb-32 img-background">
          <nav className="max-w-screen-xl mx-auto flex items-center justify-between px-4 sm:px-6">
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a aria-label="Home" href="/">
                  <img
                    className="h-8 w-auto sm:h-10"
                    src="/images/logo-s.png"
                    alt="Logo"
                  />
                </a>
                <p className="text-xl font-bold leading-6 text-white sm:truncate ml-2">
                  Vanna
                </p>
                <div className="-mr-2 flex items-center md:hidden">
                  <OutboundLink
                    target="_blank"
                    rel="noopener"
                    href="https://conta.vanna.app/entrar"
                    className="inline-flex items-center text-sm leading-5 font-medium text-white"
                  >
                    <div className="access-app">Entrar no app</div>
                  </OutboundLink>
                </div>
              </div>
            </div>
            <div className="hidden space-x-10 items-center md:flex content-center">
              <Link
                to="/funcionalidades"
                className="font-medium text-white hover:text-gray-300 transition duration-150 ease-in-out"
              >
                Funcionalidades
              </Link>
              <Link
                to="/tarifas"
                className="font-medium text-white hover:text-gray-300 transition duration-150 ease-in-out"
              >
                Tarifas
              </Link>
              <Link
                to="/seguranca"
                className="font-medium text-white hover:text-gray-300 transition duration-150 ease-in-out"
              >
                Segurança
              </Link>
              <a
                href="https://vanna.app/blog"
                rel="noopener noreferrer"
                className="font-medium text-white hover:text-gray-300 transition duration-150 ease-in-out"
              >
                Blog
              </a>
              <Link
                to="/api"
                className="font-medium text-white hover:text-gray-300 transition duration-150 ease-in-out"
              >
                API
              </Link>

              {/* <a
                href="https://docs.vanna.app"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-white hover:text-gray-300 transition duration-150 ease-in-out"
              >
                Documentação
              </a> */}
              <OutboundLink
                target="_blank"
                rel="noopener"
                href="https://api.whatsapp.com/send/?phone=5515981779550&text=Fala+Guilherme%21+Estou+interessado+na+Vanna%2C+meu+nome+%C3%A9&app_absent=0"
                className="inline-flex items-center text-sm leading-5 font-medium text-white"
              >
                <div className="access-app">Quero saber mais</div>
              </OutboundLink>
            </div>
          </nav>
          <main className="relative flex my-8 sm:my-16 md:my-20 lg:my-24 mx-auto max-w-screen-xl">
            <div
              className="absolute inset-0"
              style={{
                zIndex: 999,
                height: '400px',
                width: '45%',
                backgroundImage:
                  'radial-gradient(circle, #52588f 0%, #9bfff8 100%)',
                filter: 'blur(120px)',
              }}
            />
            <video
              className="absolute m-0 right-0 hidden lg:block border-none object-cover leading-none w-2/3"
              src="/videos/map_vanna.mp4"
              autoPlay={true}
              loop={true}
              muted={true}
              playsInline="playinline"
              controlsList="nodownload"
              alt="Vanna Map"
              style={{
                zIndex: 100,
              }}
            />
            <div className="absolute -bottom-36 -right-10 block lg:hidden">
              <StaticImage
                src="../images/map.png"
                placeholder="blurred"
                width={500}
                layout="constrained"
                alt="Mapa Brasil"
              />
            </div>
            <div className="relative px-4 sm:px-6" style={{ zIndex: 9999 }}>
              <h1 className="mt-4 text-3xl md:text-4xl tracking-tight leading-10 font-semibold text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl mb-4">
                Infraestrutura de split para Pix
              </h1>
              <p className="mt-3 text-base text-white md:text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                Use nossa infraestrutura de split para receber Pix já aplicando
                regras de divisão.
                <br className="hidden md:block" />
                Os valores caem automaticamente nas contas Vanna de seus
                dependentes.
                <br className="hidden md:block" />
                Use nossa infraestrutura para receber Pix e dividir em segundos!
              </p>
              {/* <div className="mt-8 sm:mt-8 flex justify-start">
                <OutboundLink
                  target="_blank"
                  rel="noopener"
                  href="https://apps.apple.com/us/app/vanna/id1583815274"
                  className="mr-2"
                >
                  <img
                    className="h-16 w-auto"
                    src="/images/app_store_badge.svg"
                    alt="App store"
                  />
                </OutboundLink>

                <OutboundLink
                  target="_blank"
                  rel="noopener"
                  href="https://play.google.com/store/apps/details?id=app.vanna"
                  className=""
                >
                  <img
                    className="h-16 w-auto"
                    src="/images/google_play_store_badge.svg"
                    alt="Google play store"
                  />
                </OutboundLink>
              </div> */}
              <div className="mt-8 sm:mt-8 hidden sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow-md">
                  <OutboundLink
                    target="_blank"
                    rel="noopener"
                    href="https://api.whatsapp.com/send/?phone=5515981779550&text=Fala+Guilherme%21+Estou+interessado+na+Vanna%2C+meu+nome+%C3%A9&app_absent=0"
                    className="bg-yellow-brand w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-purple-brand md:py-2 md:text-lg md:px-6"
                  >
                    Conectar com vendas &#8250;
                  </OutboundLink>
                </div>
                {/* <div className="mt-3 sm:mt-0 sm:ml-4 flex justify-center items-center">
                  <OutboundLink
                    target="_blank"
                    rel="noopener"
                    href="https://api.whatsapp.com/send/?phone=5515981779550&text=Fala+Guilherme%21+Estou+interessado+na+Vanna%2C+meu+nome+%C3%A9&app_absent=0"
                    className="font-medium hover:text-black"
                  >
                    Conectar com vendas &#8250;
                  </OutboundLink>
                </div> */}
              </div>
            </div>
          </main>
        </div>
      </section>

      {/* <HomeSoFastVideoSection /> */}
      {/* <div className="bg-gray-800">
        <div className="max-w-screen-lg mx-auto pt-16 pb-16 px-4 sm:px-6 md:pb-20 lg:px-8">
          <h3 className="text-center text-gray-400 text-sm font-semibold uppercase tracking-wide">
            200+ Clientes. Acreditado por donos de empresas que amam agilizar
            cobranças.
          </h3>
          <div className="mt-8 grid grid-cols-2 gap-8 md:grid-cols-4 lg:grid-cols-5">
            <div className="col-span-1 flex justify-center items-center md:col-span-2 lg:col-span-1">
              <a href="https://xxxxx" target="_blank">
                <img src="https://xxxxx.svg" alt="xxxxx" className="h-8" />
              </a>
            </div>
            <div className="col-span-1 flex justify-center items-center md:col-span-2 lg:col-span-1">
              <a href="https://xxxxx" target="_blank">
                <img src="https://xxxxx.svg" alt="xxxxx" className="h-8" />
              </a>
            </div>
            <div className="col-span-1 flex justify-center items-center md:col-span-2 lg:col-span-1">
              <a href="https://xxxxx" target="_blank">
                <img src="https://xxxxx.svg" alt="xxxxx" className="h-8" />
              </a>
            </div>
            <div className="col-span-1 flex justify-center items-center md:col-span-2 lg:col-span-1">
              <a href="https://xxxxx" target="_blank">
                <img src="https://xxxxx.svg" alt="xxxxx" className="h-8" />
              </a>
            </div>
            <div className="col-span-1 flex justify-center items-center md:col-span-2 lg:col-span-1">
              <a href="https://xxxxx" target="_blank">
                <img src="https://xxxxx.svg" alt="xxxxx" className="h-8" />
              </a>
            </div>
          </div>
        </div>
      </div> */}

      <section className="relative px-4 sm:px-6 py-12 lg:py-20 mx-auto max-w-screen-xl">
        <span className="uppercase text-xs font-bold pb-4">o que fazemos</span>
        <div className="max-w-3xl">
          <h2 className="text-3xl my-4 lg:text-4xl font-semibold">
            A Vanna automatiza recebimentos de Pix{' '}
            <br className="hidden md:block" /> com split.
          </h2>
          <p className="text-base xl:text-xl">
            Conte com a infraestrutura Vanna para organizar seus recebimentos de
            Pix. Ao criar um recebimento você pode aplicar diversas regras para
            dividir o valor em diversas contas de forma simples e rápida. Com
            toda expertise em pagamentos, estamos preparados para implementar
            regras de negócio desafiadoras.
          </p>
        </div>
        <div className="flex items-center justify-center lg:absolute mt-4 lg:mt-0 lg:top-4 lg:right-0">
          <StaticImage
            src="../images/vanna_multiverse.png"
            placeholder="blurred"
            width={600}
            layout="constrained"
            alt="Vanna Registrar"
          />
        </div>
        <div className="flex flex-col lg:flex-row justify-between lg:mt-24">
          <div className="py-4 lg:py-10 lg:max-w-xs">
            <h3 className="py-2 text-2xl font-bold">Anti-stress</h3>
            <div className="py-4 lg:py-6">
              <p>
                O Vanna organiza seus recebimentos, faz splits e facilita para
                que os pagamentos de seus clientes fiquem em dia, evitando assim
                situações desagradáveis e estressantes
              </p>
            </div>
          </div>
          <div className="py-4 lg:py-10 lg:max-w-xs">
            <h3 className="py-2 text-2xl font-bold">Esqueceu de cobrar?</h3>
            <div className="py-4 lg:py-6">
              <p>
                Com a Vanna você pode agendar seu recebimento e não precisa se
                preocupa em falhar no envio de suas recorrências
              </p>
            </div>
          </div>
          <div className="py-4 lg:py-10 lg:max-w-xs">
            <h3 className="py-2 text-2xl font-bold">Dinheiro na hora</h3>
            <div>
              <div className="py-4 lg:py-6">
                <p>
                  Após o recebimento os valores chegam em sua conta em até 35
                  segundos
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="py-4 lg:py-10 lg:max-w-xs">
            <h3 className="py-2 text-2xl font-bold">Controle</h3>
            <div className="py-4 lg:py-6">
              <p>
                Tenha controle de suas informações sobre recebimentos tudo
                organizado para análises rápidas
              </p>
            </div>
          </div>
          <div className="py-4 lg:py-10 lg:max-w-xs">
            <h3 className="py-2 text-2xl font-bold">Rapidez</h3>
            <div className="py-4 lg:py-6">
              <p>
                Crie novos recebimentos em menos de 15 segundos por meio do
                nosso app ou via API
              </p>
            </div>
          </div>
          <div className="py-4 lg:py-10 lg:max-w-xs">
            <h3 className="py-2 text-2xl font-bold">Avisos Automáticos</h3>
            <div>
              <div className="py-4 lg:py-6">
                <p>
                  Emails e SMS são disparados para lembrar seu cliente a pagar
                  com periodicidade acertada
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-second-pattern">
        <div className="px-4 sm:px-6 py-12 lg:py-20 mx-auto max-w-screen-xl">
          <span className="uppercase text-xs font-bold pb-4 text-right">
            na palma da mão
          </span>
          <div className="flex flex-col lg:flex-row">
            <div className="py-6 lg:py-0 lg:w-1/2">
              <StaticImage
                src="../images/iphone.png"
                placeholder="blurred"
                width={700}
                layout="constrained"
                alt="Dash Vanna"
              />
            </div>
            <div className="lg:w-1/2">
              <h2 className="text-3xl my-4 lg:text-4xl font-semibold">
                A Vanna está na mão dos stakeholders
              </h2>
              <p className="text-base xl:text-xl">
                A divisão dos recebimentos chega na palma da mão dos
                stakeholders com aplicativos Android e iOS extremamente rápidos
                e fáceis de utilizar.
              </p>
              <div className="mt-8 sm:mt-8 flex justify-start">
                <OutboundLink
                  target="_blank"
                  rel="noopener"
                  href="https://apps.apple.com/us/app/vanna/id1583815274"
                  className="mr-2"
                >
                  <img
                    className="h-16 w-auto"
                    src="/images/app_store_badge.svg"
                    alt="App store"
                  />
                </OutboundLink>

                <OutboundLink
                  target="_blank"
                  rel="noopener"
                  href="https://play.google.com/store/apps/details?id=app.vanna"
                  className=""
                >
                  <img
                    className="h-16 w-auto"
                    src="/images/google_play_store_badge.svg"
                    alt="Google play store"
                  />
                </OutboundLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="px-4 sm:px-6 py-12 lg:py-20 mx-auto max-w-screen-xl">
        <span className="uppercase text-xs font-bold pb-4">quanto custa</span>
        <div className="max-w-4xl mb-16">
          <h2 className="text-3xl my-4 lg:text-4xl font-semibold">
            Zero mensalidade
          </h2>
          <p className="text-base xl:text-xl">
            Na Vanna as taxas são cobradas apenas quando você recebe um
            pagamento <br /> Cadastre todos os dados para o split e envie suas
            cobranças com tranquilidade
          </p>
        </div>
        <div className="flex flex-row mx-auto my-10 justify-center">
          <div
            className="rounded-lg  bg-bit-transparent max-w-xs"
            style={{
              borderRadius: '0.63rem',
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0.19rem 0.75rem 0px',
              minWidth: '300px',
            }}
          >
            <p className="text-2xl font-apercu leading-normal text-center font-bold text-black pt-4">
              Sem surpresas
            </p>
            <p className="text-4xl font-inter leading-normal text-center font-bold text-black pb-4">
              <span className="font-inter text-base leading-loose text-center font-medium text-black uppercase">
                R$
              </span>
              0,00
              <span className="text-sm font-inter leading-tight text-center font-bold text-black opacity-50">
                /mensalidade
              </span>
            </p>
            <ul>
              <li className="text-xs font-inter leading-normal text-center font-medium text-black py-4 border-t border-gray-300">
                <p className="text-2xl font-inter leading-normal text-center font-bold text-black">
                  <span className="font-inter text-base leading-loose text-center font-medium text-black uppercase">
                    R$
                  </span>
                  1,99
                  <span className="text-sm font-inter leading-tight text-center font-bold text-black opacity-50">
                    /recebimento Pix
                  </span>
                </p>
              </li>
              <li className="text-xs font-inter leading-normal text-center font-medium text-black py-4 border-t border-gray-300">
                <p className="text-2xl font-inter leading-normal text-center font-bold text-black">
                  <span className="font-inter text-base leading-loose text-center font-medium text-black uppercase">
                    R$
                  </span>
                  0,50
                  <span className="text-sm font-inter leading-tight text-center font-bold text-black opacity-50">
                    /transferência Pix
                  </span>
                </p>
              </li>
              <li className="text-xs font-inter leading-normal text-center font-medium text-black py-4 border-t border-gray-300">
                <p className="text-2xl font-inter leading-normal text-center font-bold text-black">
                  <span className="font-inter text-base leading-loose text-center font-medium text-black uppercase">
                    R$
                  </span>
                  0,20
                  <span className="text-xs font-inter leading-tight text-center font-bold text-black opacity-50">
                    /cobrança não paga
                  </span>
                </p>
              </li>
            </ul>
            <div className="pt-4 text-center">
              <div className="rounded-md shadow-md">
                <OutboundLink
                  target="_blank"
                  rel="noopener"
                  href="https://api.whatsapp.com/send/?phone=5515981779550&text=Fala+Guilherme%21+Estou+interessado+na+Vanna%2C+meu+nome+%C3%A9&app_absent=0"
                  className="bg-yellow-brand hover:bg-gray-900 hover:text-white flex items-center justify-center py-3 border border-transparent text-base font-medium rounded-md text-purple-brand md:text-lg"
                >
                  Quero saber mais
                </OutboundLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-second-pattern">
        <div className="px-4 sm:px-6 py-12 lg:py-20 mx-auto max-w-screen-xl">
          <span className="uppercase text-xs font-bold pb-4">como fazemos</span>
          <div className="max-w-4xl mb-16">
            <h2 className="text-3xl my-4 lg:text-4xl font-semibold">
              Ferramentas integradas para fazer seu dinheiro chegar rápido
            </h2>
            <p className="text-base xl:text-xl">
              Use nossas ferramentas integradas para recebimento de split Pix
            </p>
          </div>
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="py-4 lg:py-10 lg:max-w-xs">
              <h3 className="py-2 text-2xl font-bold">Conta digital</h3>
              <div className="py-4 lg:py-6">
                <p>
                  Sim, os valores das cobranças chegam em sua conta digital
                  Vanna e você pode sacar ou depositar seu dinheiro quando
                  quiser
                </p>
              </div>
            </div>
            <div className="py-4 lg:py-10 lg:max-w-xs">
              <h3 className="py-2 text-2xl font-bold">Motor de cobrança</h3>
              <div className="py-4 lg:py-6">
                <p>
                  Toda experiência e melhores práticas de cobrança sem pagar
                  mais por isso. Deixe a Vanna trabalhar por você.
                </p>
              </div>
            </div>
            <div className="py-4 lg:py-10 lg:max-w-xs">
              <h3 className="py-2 text-2xl font-bold">Dados & API</h3>
              <div>
                <div className="py-4 lg:py-6">
                  <p>
                    Tenha informações relevantes para tomar as melhores decisões
                    e caso queira mais informações acesse tudo via API
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="px-4 sm:px-6 py-12 lg:py-20 mx-auto max-w-screen-xl">
        <span className="uppercase text-xs font-bold pb-4">pra quem</span>
        <div className="flex flex-col lg:flex-row">
          <div className="lg:max-w-1/2">
            <h2 className="text-3xl my-4 lg:text-4xl font-semibold">
              Split é um problema pra você?
            </h2>
            <p className="text-base xl:text-xl">
              Atendemos marketplaces, imobiliárias e sistemas de cobranças em
              que o split é uma dor.
            </p>
          </div>
          <div className="py-6 lg:py-0">
            <StaticImage
              src="../images/compiuter.png"
              placeholder="blurred"
              width={700}
              layout="constrained"
              alt="Dash Vanna"
            />
          </div>
        </div>
      </section>
      <section className="px-4 sm:px-6 py-12 lg:py-20 mx-auto max-w-screen-xl">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl lg:text-4xl font-semibold">
              Perguntas frequentes
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Não conseguiu achar o que precisa? Entre em contato via WhatsApp.
              <a
                className="text-yellow-brand hover:underline"
                target="_blank"
                rel="noopener"
                href="https://api.whatsapp.com/send/?phone=5515981779550&text=Fala+Guilherme%21+Estou+interessado+na+Vanna%2C+meu+nome+%C3%A9&app_absent=0"
              >
                Entrar em contato
              </a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:col-span-2">
            <dl className="space-y-12">
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Vocês só atendem empresas?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Sim, a plataforma Vanna foi feita para empresas e
                  microempreendedores
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Quanto preciso pagar para usar?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Você paga por recebimento! E esse valor é de R$ 1,99. Caso
                  tenha cobranças não efetuadas cobramos uma taxa mínima de R$
                  0,20, você receberá o valor atualizado sempre no mês seguinte
                  ao uso.
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Por que eu usaria a Vanna?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  <p className="pb-4">
                    São muitos motivos! Abaixo estão alguns deles:
                  </p>
                  <ul className="list-inside">
                    <li>
                      Sem erros e situações constrangedoras ao realizar splits
                      incorretos
                    </li>
                    <li>Vai sobrar mais depois das taxas</li>
                    <li>Gerenciar seus clientes em um só lugar</li>
                    <li>Analisar seus dados e previsões</li>
                    <li>Não se preocupar os recebimentos</li>
                    <li>Cobrar juros e multa por atraso</li>
                  </ul>
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Por que só Pix?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Nós apostamos forte no Pix e no potencial que ele tem de se
                  expandir no mercado digital. Você possivelmente já usa Pix em
                  seus recebimentos, você só precisa de uma plataforma que cuide
                  de seus clientes e splits para seu negócio
                </dd>
              </div>

              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Mas é seguro?
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Sim, utilizamos parceiros e melhores práticas de segurança do
                  mercado para oferecer a melhor ferramenta de cobrancås para
                  seu negócio.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>
      {/* <section className="py-12 lg:py-20 bg-gradient-to-b from-gray-100 to-white">
        <div className="max-w-xl lg:max-w-screen-xl mx-auto pb-16 sm:pb-10 px-4 sm:px-6 lg:pb-10 lg:px-8">
          <div className="relative max-w-lg mx-auto lg:max-w-7xl">
            <span className="uppercase text-xs font-bold pb-4">
              depoimentos
            </span>
            <div className="max-w-4xl mb-16">
              <h2 className="text-3xl my-4 lg:text-4xl font-semibold">
                Nossos clientes falam por nós
              </h2>
              <p className="text-base xl:text-xl">
                Veja depoimentos de alguns de nossos clientes <br /> Como
                utilizam a plataforma Vanna para fazer suas cobranças e
                gerenciar clientes e recebimentos
              </p>
            </div>
            <div className="mt-14 grid gap-10 lg:grid-cols-3 lg:gap-x-10 lg:gap-y-12">
              <div className="bg-white px-10 py-8 shadow-lg overflow-hidden rounded-lg flex flex-col justify-between">
                <div className="text-base leading-6 text-gray-500 relative z-0">
                  <span className="absolute top-0 left-0 -mt-12 -ml-8">
                    <span
                      className="text-gray-100 relative leading-none"
                      style={{ fontSize: '20rem', zIndex: -1 }}
                    >
                      “
                    </span>
                  </span>
                  <p className="relative z-10">
                    Tem sido muito legal o suporte que o pessoal da Vanna tem
                    dado! Sem falar que é{' '}
                    <span
                      className="inline-block mt-0 rounded-lg font-medium text-yellow-800 bg-yellow-100 relative"
                      style={{ zIndex: -1 }}
                    >
                      extremamente fácil
                    </span>{' '}
                    criar novos produtos e clientes.
                  </p>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <StaticImage
                      src="../images/eli.jpeg"
                      placeholder="blurred"
                      width={48}
                      layout="constrained"
                      alt="Eliabe Junior"
                      className="h-12 w-12 rounded-full"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm leading-5 font-medium text-gray-900">
                      <span>Eliabe Junior</span>
                    </p>
                    <div className="text-sm leading-5 text-gray-500">
                      Estrategista Digital
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white px-10 py-8 shadow-lg overflow-hidden rounded-lg flex flex-col justify-between">
                <div className="text-base leading-6 text-gray-500 relative z-0">
                  <span className="absolute top-0 left-0 -mt-12 -ml-8">
                    <span
                      className="text-gray-100 relative leading-none"
                      style={{ fontSize: '20rem', zIndex: -1 }}
                    >
                      “
                    </span>
                  </span>
                  <p className="relative z-10">
                    O, Vanna me deu{' '}
                    <span
                      className="inline-block mt-0 rounded-lg font-medium text-yellow-800 bg-yellow-100 relative"
                      style={{ zIndex: -1 }}
                    >
                      mais tempo e segurança
                    </span>{' '}
                    para cobrar meus clientes. O resumo deles me mostra
                    exatamente quando recebi minhas cobranças e até mesmo
                    projeções quando crio vendas recorrentes.
                  </p>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <span>
                      <StaticImage
                        src="../images/cris.jpeg"
                        placeholder="blurred"
                        width={48}
                        layout="constrained"
                        alt="Christine Nery"
                        className="h-12 w-12 rounded-full"
                      />
                    </span>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm leading-5 font-medium text-gray-900">
                      <span>Christine Nery</span>
                    </p>
                    <div className="text-sm leading-5 text-gray-500">
                      Agência digital Lumine
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white px-10 py-8 shadow-lg overflow-hidden rounded-lg flex flex-col justify-between">
                <div className="text-base leading-6 text-gray-500 relative z-0">
                  <span className="absolute top-0 left-0 -mt-12 -ml-8">
                    <span
                      className="text-gray-100 relative leading-none"
                      style={{ fontSize: '20rem', zIndex: -1 }}
                    >
                      “
                    </span>
                  </span>
                  <p className="relative z-10">
                    A Vanna me deixa{' '}
                    <span
                      className="inline-block mt-0 rounded-lg font-medium text-yellow-800 bg-yellow-100 relative"
                      style={{ zIndex: -1 }}
                    >
                      tranquila
                    </span>{' '}
                    e meus clientes sabem que estou usando uma plataforma de
                    cobranças que tem juros e multa e nunca mais faltaram os
                    pagamentos.
                  </p>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <StaticImage
                      src="../images/fer.png"
                      placeholder="blurred"
                      width={48}
                      layout="constrained"
                      alt="Fernanda Canova"
                      className="h-12 w-12 rounded-full"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm leading-5 font-medium text-gray-900">
                      <span>Fernanda Canova</span>
                    </p>
                    <div className="text-sm leading-5 text-gray-500">
                      Consultoria para Zootecnistas
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="px-4 sm:px-6 pb-12 lg:pb-28 mx-auto max-w-screen-xl">
        <div className="bg-blacki rounded-md py-8 px-8">
          <h2 className="text-2xl font-medium text-center text-white">
            Entre em contato para saber mais como receber split Pix
          </h2>
          <div className="flex flex-col justify-center items-center">
            <StaticImage
              src="../images/vanna_signup.png"
              placeholder="blurred"
              width={600}
              layout="constrained"
              alt="Vanna Registrar"
            />
            <div className="sm:flex justify-center">
              <div className="rounded-md shadow-md">
                <OutboundLink
                  target="_blank"
                  rel="noopener"
                  href="https://api.whatsapp.com/send/?phone=5515981779550&text=Fala+Guilherme%21+Estou+interessado+na+Vanna%2C+meu+nome+%C3%A9&app_absent=0"
                  className="bg-yellow-brand w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-purple-brand md:py-2 md:text-lg md:px-6 mt-6"
                >
                  Entrar em contato
                </OutboundLink>
              </div>
              {/* <div className="mt-3 sm:mt-0 sm:ml-4 shadow-md">
                <Link
                  to="/funcionalidades"
                  className="bg-black w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white md:py-2 md:text-lg md:px-6"
                >
                  Quero saber mais
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <footer>
        <p className="w-full text-sm text-center text-md py-2">
          Copyright © {new Date().getFullYear()} Vanna. Todos os direitos
          reservados.
          <Link
            to="/funcionalidades"
            className="text-yellow-brand hover:underline"
          >
            Termos de uso
          </Link>{' '}
          e{' '}
          <Link
            to="/funcionalidades"
            className="text-yellow-brand hover:underline"
          >
            Política de privacidade
          </Link>
        </p>
        <div className="flex items-center justify-center mb-4">
          <a
            href="https://instagram.com/vanna.app"
            className="text-yellow-brand hover:underline pr-3"
          >
            Instagram
          </a>
          <a
            href="https://facebook.com/vanna.app"
            className="text-yellow-brand hover:underline pr-3"
          >
            Facebook
          </a>
          <a
            href="https://twitter.com/vanna_app"
            className="text-yellow-brand hover:underline pr-3"
          >
            Twitter
          </a>
        </div>
      </footer>
    </Layout>
  )
}

export default IndexPage
